import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';

const NavBar = () => {
  return (
    <Navbar className="navbar" expand="lg" sticky="top" variant="dark">
      <Container>
        <Navbar.Brand href="/">Pure Hues</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown title="Products" id="basic-nav-dropdown">
              <NavDropdown.Item href="/products/synthetic-food-color">Synthetic Food Color</NavDropdown.Item>
              <NavDropdown.Item href="/products/natural-food-color">Natural Food Color</NavDropdown.Item>
              <NavDropdown.Item href="/products/lake-color">Lake Color</NavDropdown.Item>
              <NavDropdown.Item href="/products/dc-food-color">D&C Food Color</NavDropdown.Item>
              <NavDropdown.Item href="/products/cosmetic-color">Cosmetic Color</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/contact">Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
