import React, { useEffect } from 'react';
import { Container, Card, Row, Col, Button, Carousel } from 'react-bootstrap';
import facilityImage from '../Images/facility.png';

import Natural from '../Images/natural-food-home.png';
import Synthetic from '../Images/synthetic-food-home.png';
import Lake from '../Images/lake-food-home.png';
import DC from '../Images/dc-food-home.png';
import Cosmetic from '../Images/cosmetic-food-home.png';
import { useNavigate } from 'react-router-dom';


const Home = () => {

  const navigate = useNavigate();
  
useEffect(() => {
  const container = document.querySelector('.product-scroll-container');
  const leftButton = document.querySelector('.scroll-left');
  const rightButton = document.querySelector('.scroll-right');

  const toggleScrollButtons = () => {
    const canScrollLeft = container.scrollLeft > 0;
    const canScrollRight = container.scrollLeft < container.scrollWidth - container.clientWidth;

    container.classList.toggle('can-scroll-left', canScrollLeft);
    container.classList.toggle('can-scroll-right', canScrollRight);
  };

  container.addEventListener('scroll', toggleScrollButtons);
  window.addEventListener('resize', toggleScrollButtons);

  // Initial check
  toggleScrollButtons();

  return () => {
    container.removeEventListener('scroll', toggleScrollButtons);
    window.removeEventListener('resize', toggleScrollButtons);
  };
}, []);

const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};


  return (
    <>
      <div className="home-jumbotron text-center">
        <h1>Infuse Life with Vibrant Colors</h1>
        <p className="lead">
            Discover the Art of Color with Our Premium Food Dyes
        </p>
        <Button style={{background: 'lavender'}} className="custom-button" variant="custom" size="lg" onClick={() => scrollTo("products")}>Explore Our Products</Button>        
     </div>

      <Container>
      <section id="products" className='our-products mb-5'>
  <h2 className="text-center mb-4">Our Products</h2>
  <div className="position-relative product-carousel">
    <div className="product-scroll-container overflow-hidden">
      <Row className="flex-nowrap">
        {[
          { title: "Natural Food Colors", link:'/products/natural-food-color', img: Natural, description: "Natural food colors are derived from pure botanical sources, offering a wholesome and authentic way to enhance the visual appeal of your culinary creations. Extracted from fruits, vegetables, and other natural ingredients, these colors provide subtle, earthy hues that reflect the beauty of nature. Ideal for health-conscious consumers and clean label products, natural food colors ensure that your dishes and beverages not only look vibrant but also align with natural and organic standards. Perfect for everything from baked goods to beverages, these colors bring a touch of nature’s palette to your kitchen." },
          { title: "Synthetic Food Colors", link:'/products/synthetic-food-color', img: Synthetic, description: "Synthetic food colors are artificial additives crafted to enhance the visual appeal of food and beverages with vibrant, consistent hues. Produced through precise chemical processes, these colors offer a wide spectrum of shades, ensuring that your culinary creations look as appealing as they taste. Ideal for commercial food production, synthetic food colors are highly stable, allowing for uniform color distribution in a variety of applications, from candies and baked goods to drinks and sauces. Safe and reliable, they help bring eye-catching brilliance to your products." },
          { title: "Lake Colors", link:'/products/lake-color', img: Lake, description: "Lake food colors are stable, water-insoluble pigments created by combining dyes with metallic salts, making them ideal for applications requiring vibrant and long-lasting color. Known for their excellent dispersibility in oil-based and dry products, lake colors are perfect for items like coated tablets, baked goods, and confections. These colors provide consistent, intense hues that are resistant to moisture and light, ensuring your products maintain their visual appeal over time. Lake food colors are versatile and reliable, offering a high-quality solution for achieving brilliant colors in a wide range of food applications." },
          { title: "D&C Food Colors", link:'/products/dc-food-color', img: DC, description: "D&C food colors are specialized dyes approved for use in drugs and cosmetics, providing vibrant and consistent hues essential for product appeal. These high-quality colors are meticulously formulated to meet FDA regulations, ensuring safety and reliability in a wide range of applications. Ideal for enhancing the appearance of pharmaceuticals and cosmetic products, D&C food colors are available in various shades to suit your specific needs." },
          { title: "Cosmetic Colors", link:'/products/cosmetic-food-color', img: Cosmetic, description: "Cosmetic food colors are specially formulated pigments designed to enhance the visual appeal of beauty and personal care products with vibrant, long-lasting hues. These high-quality colors meet stringent safety standards, ensuring they are safe for use on the skin and in cosmetic formulations. Ideal for products such as lip balms, lotions, soaps, and eyeshadows, cosmetic food colors provide consistent and intense shades that can be customized to suit a variety of cosmetic applications. Their versatility and safety make them the perfect choice for creating stunning, colorful cosmetic products that stand out on the shelf." }
        ].map((product, index) => (
          <Col key={index} md={4} sm={6} className="mb-3 flex-shrink-0">
            <Card className="h-100 border shadow-sm">
              <Card.Img variant="top" src={product.img} />
              <Card.Body>
                <Card.Title>{product.title}</Card.Title>
                <Card.Text>{product.description}</Card.Text>
                <Button variant="custom" style={{ background: 'lavender' }} onClick={() => navigate(product.link)}>View Products</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
    <Button
      variant="light"
      className="scroll-button scroll-left position-absolute top-50 start-0 translate-middle-y"
      onClick={() => {
        const container = document.querySelector('.product-scroll-container');
        container.scrollLeft -= container.offsetWidth;
      }}
    >
      <i className="fas fa-chevron-left" style={{ color: '#000', fontSize: '1.5rem' }}></i>
    </Button>
    <Button
      variant="light"
      className="scroll-button scroll-right position-absolute top-50 end-0 translate-middle-y"
      onClick={() => {
        const container = document.querySelector('.product-scroll-container');
        container.scrollLeft += container.offsetWidth;
      }}
    >
      <i className="fas fa-chevron-right" style={{ color: '#000', fontSize: '1.5rem' }}></i>
    </Button>
  </div>
</section>

<style jsx>{`
  .product-carousel {
    padding: 0 30px;
  }

  .product-scroll-container {
    overflow-x: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .product-scroll-container::-webkit-scrollbar {
    display: none;
  }

  .scroll-button {
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid #fff;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    opacity: 0.7;
    transition: opacity 0.3s;
  }

  .scroll-button:hover {
    opacity: 1;
  }

  .scroll-button.scroll-left {
    left: 0;
    border-radius: 0 5px 5px 0;
  }

  .scroll-button.scroll-right {
    right: 0;
    border-radius: 5px 0 0 5px;
  }
`}</style>


        {/* Why Choose PureHues */}
        <section className="why-choose-us mb-5">
        <h2 className="text-center mb-4" style={{ color: 'black' }}>Why Choose Pure Hues?</h2>
        <Row>
            {[
            { reason: 'Quality', icon: 'star', color: '#E7717D' },
            { reason: 'Innovation', icon: 'lightbulb', color: '#C2CAD0' },
            { reason: 'Reliability', icon: 'handshake', color: '#C2B9B0' },
            { reason: 'Customization', icon: 'palette', color: '#AFD275' }
            ].map(({ reason, icon, color }, index) => (
            <Col key={index} md={3} sm={6} className="mb-3">
                <Card className="text-center" style={{ borderColor: color, backgroundColor: color, color: 'black' }}>
                <Card.Body>
                    <Card.Title style={{ color: 'black' }}>{reason}</Card.Title>
                </Card.Body>
                </Card>
            </Col>
            ))}
        </Row>
        </section>

        {/* Who We Are */}
        <section className="who-we-are mb-5 p-5 bg-light rounded">
            <h2 className="text-center mb-4">Who We Are</h2>
            <p style={{textAlign: 'justify'}}>
                Pure Hues is a leading provider of high-quality food colors, serving the global market with innovative and reliable coloring solutions. 
                With our state-of-art manufacturing facility in India, we combine traditional expertise with cutting-edge technology to deliver products that meet the highest standards of quality and safety.
               
Our extensive range of natural and synthetic food colors caters to various applications, from beverages and confectionery to dairy products and baked goods. 
Our commitment to quality is unwavering. We source the finest raw materials and subject our products to rigorous testing protocols to ensure consistency, stability, and safety. As we look to the future, we remain committed to pushing the boundaries of what's possible in food coloring. Whether you're a multinational corporation or a local artisanal producer, Pure Hues is your trusted partner in bringing vibrant, safe, and innovative colors to your products.
            </p>
            <img
                src={facilityImage}
                alt="Pure Hues Facility"
                className="img-fluid rounded"
                style={{ width: '100%', height: 'auto', maxHeight: '450px', objectFit: 'cover' }}
            />
        </section>

        {/* How We Work */}
        <section className="how-we-work mb-5">
          <h2 className="text-center mb-4">How We Work</h2>
          <Row>
            <Col key={1} md={4}>
              <div className="process-step text-center">
                <div className="process-number">1</div>
                <h4>Consultation</h4>
                <p>We consult with you to understand your specific product needs.</p>
              </div>
            </Col>
            <Col key={2} md={4}>
              <div className="process-step text-center">
                <div className="process-number">2</div>
                <h4>Sampling</h4>
                <p>We provide curated color samples to ensure the perfect match for your products.</p>
              </div>
            </Col>
            <Col key={3} md={4}>
              <div className="process-step text-center">
                <div className="process-number">3</div>
                <h4>Delivery</h4>
                <p>We handle packaging and deliver the final product to your doorstep.</p>
              </div>
            </Col>
          </Row>
        </section>

      </Container>
    </>
  );
};

export default Home;
