import React from 'react';
import HorizontalAccordion from '../Common/Product';
import synthetic from './synthetic.png';

const syntheticFoodColors = [
  { name: 'Tartrazine', color: '#F5D95A', info: 'A popular synthetic red dye used in various food products.' },
  { name: 'Quinoline Yellow', color: '#F3EB5F', info: 'A synthetic yellow dye commonly used in beverages and snacks.' },
  { name: 'Sunset Yellow', color: '#CF5D43', info: 'A vibrant blue dye used in candies, beverages, and other food products.' },
  { name: 'Erythrosine', color: '#C94283', info: 'A synthetic green dye used in a variety of food and beverage applications.' },
  { name: 'Allura Red', color: '#C53734', info: 'A synthetic orange dye used in snacks and beverages.' },
  { name: 'Red 2G', color: '#C13733', info: 'Used in food products for its bright pink color.' },
  { name: 'Phloxine B', color: '#A13230', info: 'A synthetic dye used in various food applications for its deep blue color.' },
  { name: 'Ponceau 4R', color: '#A53331', info: 'A synthetic dye providing a vibrant green color.' },
  { name: 'Carmoisine', color: '#77262D', info: 'A synthetic dye used in food products for its bright orange color.' },
  { name: 'Amarnath', color: '#351620', info: 'A synthetic dye used in various food applications for its purple hue.' },
  { name: 'Brilliant Blue FCF', color: '#33487A', info: 'A synthetic dye used in various food applications for its purple hue.' },
  { name: 'Indigo Carmine', color: '#33487A', info: 'A synthetic dye used in various food applications for its purple hue.' },
  { name: 'Patent Blue V', color: '#30487E', info: 'A synthetic dye used in various food applications for its purple hue.' },
  { name: 'Fast Green FCF', color: '#325431', info: 'A synthetic dye used in various food applications for its purple hue.' },
  { name: 'Green-S', color: '#1E3E27', info: 'A synthetic dye used in various food applications for its purple hue.' },
  { name: 'Chocolate Brown HT', color: '#422C1D', info: 'A synthetic dye used in various food applications for its purple hue.' },
  { name: 'Black PN', color: '#0A0B09', info: 'A synthetic dye used in various food applications for its purple hue.' },
];

const SyntheticFoodColorPage = () => {
  return (
    <div>
      <HorizontalAccordion
        title="Synthetic Food Colors"
        description="Our synthetic food colors provide bright, consistent, and stable colors for a wide range of food and beverage applications."
        products={syntheticFoodColors}
        backgroundImage={synthetic}
        overviewText={
          "Pure Hues offers an extensive range of synthetic food colors to meet all your needs. Below are some of our primary colors, but we can also create a custom product tailored to your specific requirements. Elevate your culinary creations with our vibrant, consistent, and high-quality synthetic food colors."
        }
      />
    </div>
  );
};

export default SyntheticFoodColorPage;
