import React from 'react';
import { Container } from 'react-bootstrap';
import './Product.css';

const HorizontalAccordion = ({ title, description, products, backgroundImage, overviewText }) => {
  return (
    <div className="product-page">
      <div className="hero-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <Container className="hero-content">
          <h1 className="text-center">{title}</h1>
          <p className="lead text-center">{description}</p>
        </Container>
      </div>
      <Container className="product-list-section">
        <div className="overview-section">
          <p className="overview-text">{overviewText}</p>
        </div>
        <div className="product-tiles">
          {products.map((product, index) => (
            <div key={index} className="product-tile" style={{ borderColor: product.color }}>
              <div className="tile-body">
                <div className="color-tile" style={{ backgroundColor: product.color }}></div>
                <div className="product-info">
                  <h3>{product.name}</h3>
                  <p>{product.info}</p>
                  {product.applications && (
                    <div>
                      <h4>Applications</h4>
                      <ul>
                        {product.applications.map((app, i) => (
                          <li key={i}>{app}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default HorizontalAccordion;