import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './Components/Navbar';
import Home from './Components/Home';
import About from './Components/About';
import Contact from './Components/Contact';
import './App.css';
import Footer from './Components/Footer';
import NaturalFoodColorPage from './Components/Products/NaturalFoodColorPage';
import SyntheticFoodColorPage from './Components/Products/SyntheticFoodColorPage';
import LakeColorPage from './Components/Products/LakeColorPage';
import DCFoodColorPage from './Components/Products/DCFoodColorPage';
import CosmeticColorPage from './Components/Products/CosmeticColor';

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products/natural-food-color" element={<NaturalFoodColorPage />} />
        <Route path="/products/synthetic-food-color" element={<SyntheticFoodColorPage />} />
        <Route path="/products/lake-color" element={<LakeColorPage />} />
        <Route path="/products/dc-food-color" element={<DCFoodColorPage />} />
        <Route path="/products/cosmetic-color" element={<CosmeticColorPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
