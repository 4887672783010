import React from 'react';
import HorizontalAccordion from '../Common/Product';
import lake from './lake.png';

const lakeColors = [
  { name: 'Lake Quinoline Yellow', color: '#F7EC52', info: 'C.I. No. 47005 - A water-insoluble colorant used in tablets and capsules.' },
  { name: 'Lake Tartrazine', color: '#F6DD54', info: 'C.I. No. 19140 - Used in coated tablets and cosmetics for its bright yellow hue.' },
  { name: 'Lake Sunset Yellow FCF', color: '#D67B3E', info: 'C.I. No. 15985 - A stable blue colorant used in pharmaceuticals and cosmetics.' },
  { name: 'Lake Erythrosine', color: '#CD578D', info: 'C.I. No. 45430 - Used in various applications including pharmaceuticals and personal care products.' },
  { name: 'Lake Allura Red', color: '#B73533', info: 'C.I. No. 16035 - Provides a stable orange color for pharmaceuticals and cosmetics.' },
  { name: 'Lake Ponceau 4R', color: '#A93331', info: 'C.I. No. 16255 - A stable pink color used in cosmetics and pharmaceuticals.' },
  { name: 'Lake Phloxine 13', color: '#A63331', info: 'C.I. No. 45410 - Used in pharmaceuticals and cosmetics for its vibrant orange color.' },
  { name: 'Lake Amaranth', color: '#441727', info: 'C.I. No. 16185 - A stable blue colorant for use in various applications.' },
  { name: 'Lake Carmoisine', color: '#8D2E2C', info: 'C.I. No. 14720 - Provides a stable green color for pharmaceuticals and cosmetics.' },
  { name: 'Chocolate Brown HT', color: '#422C1D', info: 'C.I. No. 20285 - Used in cosmetics and pharmaceuticals for its rich brown color.' },
  { name: 'Lake Brilliant Blue FCF', color: '#398DAE', info: 'C.I. No. 42090 - Used in cosmetics and pharmaceuticals for its rich brown color.' },
  { name: 'Lake Green-S', color: '#1C4028', info: 'C.I. No. 44090 - Used in cosmetics and pharmaceuticals for its rich brown color.' },
  { name: 'Lake Indigo Carmine', color: '#1F1E49', info: 'C.I. No. 73015 - Used in cosmetics and pharmaceuticals for its rich brown color.' },
  { name: 'Lake Patent Blue V', color: '#283E79', info: 'C.I. No. 42051 - Used in cosmetics and pharmaceuticals for its rich brown color.' },
  { name: 'Lake Black PN', color: '#0A0B09', info: 'C.I. No. 28440 - Used in cosmetics and pharmaceuticals for its rich brown color.' },
];

const LakeColorPage = () => {
  return (
    <div>
      <HorizontalAccordion
        title="Lake Colors"
        description="Our lake colors are highly stable and versatile, perfect for use in pharmaceuticals and cosmetics. They provide vibrant and long-lasting colors."
        products={lakeColors}
        backgroundImage={lake}
        overviewText={
          "Pure Hues offers an extensive range of Lake colors to meet all your needs. Below are some of our primary colors, but we can also create a custom product tailored to your specific requirements. Elevate your culinary creations with our vibrant, consistent, and high-quality synthetic food colors."
        }
      />
    </div>
  );
};

export default LakeColorPage;
