import React from 'react';
import { Container } from 'react-bootstrap';

const About = () => {
  return (
    <Container>
      <h1>About Us</h1>
      <p>
        Pure Hues is committed to providing high-quality food colors. Our plant in India adheres to maximum quality standards, ensuring that all our products are FDA compliant.
      </p>
    </Container>
  );
};

export default About;
