import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faComments, faFileAlt } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {
  const iconColor = "#7E685A";

  return (
    <div style={{ background: 'linear-gradient(90deg, var(--pink), var(--light-gray))'}}>
      <Container className="py-5" style={{minHeight: '100vh'}}>
        <h1 className="text-center mb-5">Get in Touch</h1>
        <Row className="justify-content-center mb-5">
          <Col md={10} lg={8}>
            <Card className="shadow-lg">
              <Card.Body className="p-5">
                <h2 className="mb-4">Let's Talk</h2>
                <p className="lead mb-4">
                  We're here to answer any questions you may have about our products and services. 
                  Reach out to us and we'll respond as soon as we can.
                </p>
                <Row>
                  <Col md={6} className="mb-4">
                    <Card className="h-100 border-0 shadow-sm">
                      <Card.Body className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faEnvelope} size="2x" className="me-3" style={{ color: iconColor }} />
                        <div>
                          <h5 className="mb-1">Email Us</h5>
                          <Card.Link href="mailto:info@pure-hues.com">info@pure-hues.com</Card.Link>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;