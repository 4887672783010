import React from 'react';
import HorizontalAccordion from '../Common/Product';
import dc from './d&c.png';

const dcFoodColors = [
  { name: 'D&C Yellow 7', color: '#BECF54', info: 'Extracted from cochineal insects, commonly used in lipsticks and blushes.' },
  { name: 'D&C Yellow 8', color: '#BECF54', info: 'Derived from mica minerals, used in eye shadows and highlighters for a shimmering effect.' },
  { name: 'D&C Yellow 10', color: '#F5EB52', info: 'Natural pigments used in foundations and bronzers for earthy tones.' },
  { name: 'D&C Yellow 11', color: '#EFEB73', info: 'Used in sunscreens and whitening products for its UV blocking properties.' },
  { name: 'D&C Yellow 33', color: '#F5EB52', info: 'Derived from natural lapis lazuli, used in eyeshadows and eyeliners.' },
  { name: 'D&C Orange 4', color: '#CF5C38', info: 'Used in sunscreens and skincare products for its protective properties.' },
  { name: 'D&C Red 21', color: '#C9453F', info: 'Used in cosmetics for its vibrant green shade.' },
  { name: 'D&C Red 22', color: '#9D3230', info: 'A synthetic dye used in various cosmetic products.' },
  { name: 'D&C Red 27', color: '#C94168', info: 'Natural colorant derived from annatto seeds, used in lipsticks and blushes.' },
  { name: 'D&C Red 28', color: '#C94168', info: 'Used in cosmetics for a metallic shimmer effect.' },
  { name: 'D&C Red 30', color: '#C73834', info: 'Used in cosmetics for a metallic shimmer effect.' },
  { name: 'D&C Red 33', color: '#9D3230', info: 'Used in cosmetics for a metallic shimmer effect.' },
  { name: 'D&C Green 5', color: '#3C978C', info: 'Used in cosmetics for a metallic shimmer effect.' },
  { name: 'D&C Green 6', color: '#194C51', info: 'Used in cosmetics for a metallic shimmer effect.' },
  { name: 'D&C Blue 1', color: '#398DAE', info: 'Used in cosmetics for a metallic shimmer effect.' },
  { name: 'D&C Violet 2', color: '#463177', info: 'Used in cosmetics for a metallic shimmer effect.' },
];

const DCFoodColorPage = () => {
  return (
    <div>
      <HorizontalAccordion
        title="D&C Food Colors"
        description="Our D&C food colors are approved for use in drugs and cosmetics, offering a range of vibrant and safe color options."
        products={dcFoodColors}
        backgroundImage={dc}
        overviewText={
          "Pure Hues offers an extensive range of D&C Food colors to meet all your needs. Below are some of our primary colors, but we can also create a custom product tailored to your specific requirements. Elevate your culinary creations with our vibrant, consistent, and high-quality synthetic food colors."
        }
      />
    </div>
  );
};

export default DCFoodColorPage;
