import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-dark text-white py-4">
      <Container>
        <Row>
          <Col md={4} className="mb-3 mb-md-0">
            
          </Col>
          <Col md={4} className="mb-3 mb-md-0">
          </Col>
          <Col md={4} className="text-md-end">
            <h5>Pure Hues</h5>
            <p>&copy; {new Date().getFullYear()} Pure Hues. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
