import React from 'react';
import HorizontalAccordion from '../Common/Product';
import natural from './natural.png';

const naturalFoodColors = [
  { name: 'Caramel Type I: Plain Scotch Grade', color: '#422C1D', info: 'Derived from beets, this natural red color is perfect for adding vibrant red hues to your products.' },
  { name: 'Caramel Type II: Caustic Sulphite Grade', color: '#422C1D', info: 'Extracted from turmeric, this yellow color adds a bright, warm tone to food products.' },
  { name: 'Caramel Type III: Beer Grade', color: '#422C1D', info: 'Made from spirulina algae, this green color is a natural choice for health-conscious products.' },
  { name: 'Caramel Type IV: Single Strength', color: '#422C1D', info: 'Sourced from blueberries, this blue color is ideal for a range of applications.' },
  { name: 'Caramel Type IV: Double Strength', color: '#1F1311', info: 'Derived from carrots, this orange color is perfect for adding a vibrant, natural look.' },
  { name: 'Caramel Powder', color: '#1F1311', info: 'Made from spinach leaves, this green color is both vibrant and natural.' },
  { name: 'Annatto Oil Soluble', color: '#DE9249', info: '1.5% Bixin: Extracted from cochineal insects, this red color is commonly used in confectionery and cosmetics.' },
  { name: 'Annatto Water Soluble', color: '#DE9249', info: '1.5% Nor-Bixin: Derived from paprika, this orange-red color adds a warm tone to food products.' },
  { name: 'Curcumine Color', color: '#E09945', info: 'Sourced from saffron, this yellow color is highly prized for its vibrant hue and flavor.' },
  { name: 'Red Beet Root Powder', color: '#C63734', info: '0.3% to 0.45% Betanine: Made from elderberries, this purple color adds a rich, natural tone to various products.' },
  { name: 'Paprika Color Water Soluble', color: '#C63734', info: '40,000 i.u.: Made from elderberries, this purple color adds a rich, natural tone to various products.' },
  { name: 'Chlorophyll Green', color: '#43683B', info: 'Made from elderberries, this purple color adds a rich, natural tone to various products.' },
  { name: 'Carmine Water Soluble', color: '#531C1C', info: '45%, 50% and 60% strength: Made from elderberries, this purple color adds a rich, natural tone to various products.' },
  { name: 'Carmine Oil Soluble', color: '#531C1C', info: '45%, 50% and 60% strength: Made from elderberries, this purple color adds a rich, natural tone to various products.' },
  { name: 'Anthocyanin', color: '#442042', info: '0.3% to 0.45% Betanine: Made from elderberries, this purple color adds a rich, natural tone to various products.' },
];

const NaturalFoodColorPage = () => {
  return (
    <div>
      <HorizontalAccordion
        title="Natural Food Colors"
        description="Our natural food colors are derived from natural sources and are safe for consumption. They are perfect for adding vibrant colors to your food products."
        products={naturalFoodColors}
        backgroundImage={natural}
        overviewText={
          "Pure Hues offers an extensive range of Natural food colors to meet all your needs. Below are some of our primary colors, but we can also create a custom product tailored to your specific requirements. Elevate your culinary creations with our vibrant, consistent, and high-quality synthetic food colors."
        }
      />
    </div>
  );
};

export default NaturalFoodColorPage;
