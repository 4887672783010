import React from 'react';
import HorizontalAccordion from '../Common/Product';
import cosmetic from './cosmetic.png';

const cosmeticColors = [
  { name: 'D&C Yellow 7 Lake', color: '#BECF54', info: 'Extracted from cochineal insects, commonly used in lipsticks and blushes.' },
  { name: 'D&C Yellow 8 Lake', color: '#BECF54', info: 'Derived from mica minerals, used in eye shadows and highlighters for a shimmering effect.' },
  { name: 'D&C Yellow 10 Lake', color: '#F5EB52', info: 'Natural pigments used in foundations and bronzers for earthy tones.' },
  { name: 'D&C Yellow 11 Lake', color: '#EFEB73', info: 'Used in sunscreens and whitening products for its UV blocking properties.' },
  { name: 'D&C Yellow 33 Lake', color: '#F5EB52', info: 'Derived from natural lapis lazuli, used in eyeshadows and eyeliners.' },
  { name: 'D&C Orange 4 Lake', color: '#CF5C38', info: 'Used in sunscreens and skincare products for its protective properties.' },
  { name: 'D&C Red 21 Lake', color: '#C9453F', info: 'Used in cosmetics for its vibrant green shade.' },
  { name: 'D&C Red 22 Lake', color: '#9D3230', info: 'A synthetic dye used in various cosmetic products.' },
  { name: 'D&C Red 27 Lake', color: '#C94168', info: 'Natural colorant derived from annatto seeds, used in lipsticks and blushes.' },
  { name: 'D&C Red 28 Lake', color: '#C94168', info: 'Used in cosmetics for a metallic shimmer effect.' },
  { name: 'D&C Red 30 Lake', color: '#C73834', info: 'Used in cosmetics for a metallic shimmer effect.' },
  { name: 'D&C Red 33 Lake', color: '#9D3230', info: 'Used in cosmetics for a metallic shimmer effect.' },
  { name: 'D&C Red 6 BA Lake', color: '#C73834', info: 'Used in cosmetics for a metallic shimmer effect.' },
  { name: 'D&C Red 7 CA Lake', color: '#541C1E', info: 'Used in cosmetics for a metallic shimmer effect.' },
  { name: 'D&C Green 5 Lake', color: '#3C978C', info: 'Used in cosmetics for a metallic shimmer effect.' },
  { name: 'D&C Green 6 Lake', color: '#194C51', info: 'Used in cosmetics for a metallic shimmer effect.' },
  { name: 'D&C Blue 1 Lake', color: '#398DAE', info: 'Used in cosmetics for a metallic shimmer effect.' },
  { name: 'D&C Violet 2 Lake', color: '#463177', info: 'Used in cosmetics for a metallic shimmer effect.' },
];

const CosmeticColorPage = () => {
  return (
    <div>
      <HorizontalAccordion
        title="Cosmetic Colors"
        description="Our cosmetic colors are safe, high-quality pigments used in a variety of beauty products. They provide vibrant and long-lasting colors."
        products={cosmeticColors}
        backgroundImage={cosmetic}
        overviewText={
          "Pure Hues offers an extensive range of Cosmetic colors to meet all your needs. Below are some of our primary colors, but we can also create a custom product tailored to your specific requirements. Elevate your culinary creations with our vibrant, consistent, and high-quality synthetic food colors."
        }
      />
    </div>
  );
};

export default CosmeticColorPage;
